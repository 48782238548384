import React from 'react';
import { isMobile } from '../../utils/browser-util';
import StandByImg from '../../assets/stand_by.svg';
import './Messages.css';

/*
On Wall
  No auto OB
    Waiting for servers
    Waiting for screening
    Waiting for start event
    Waiting for rehearsal

  Auto OB
    Waiting for servers
    Waiting for start event
    Waiting for rehearsal

Off Wall
  No auto OB
    Waiting for servers
    Waiting for start event
    Waiting for rehearsal

  Auto OB
    Waiting for servers
    Waiting for start event
    Waiting for rehearsal

Broadcast
  Waiting for servers
  Waiting for start event
*/

function Waiting({ showName, waitingForServers, waitForRehearsal, isEventLive, isEventEnded, isBroadcast }) {
  let message;
  if (isEventEnded) {
    message = 'We are sorry, it appears that the event has already ended.';
  } else if (waitingForServers || isBroadcast) {
    message = 'The event will be starting shortly. You will be redirected automatically once it begins. Please stand by…';
  } else if (waitForRehearsal && isEventLive) {
    message = 'Please stand by as rehearsal is in progress. You will be redirected automatically once the event begins.';
  } else {
    message = 'The event has not yet started. Please stand by while others are being onboarded.';
  }

  return (
    <div className='message-box' style={isMobile() ? { transform: 'scale(0.7)' } : {}}>
      <span className='show-id'>{showName}</span>
      <img src={StandByImg} alt='waiting' />
      <span className='text green'>{message}</span>
    </div>
  );
}

export default Waiting;
