import React, { useState, useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';

import { store } from '../../store';
import { post, setStudioId, setToken } from '../../services/api';
import MediaDeviceUtil from '../../utils/media-device-util';

import LoginForm from './LoginForm';
import './VenueLogin.css';

function VenueLogin() {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  const studioId = urlParams.get('studioId');
  const eventId = urlParams.get('eventId');

  useEffect(() => {
    if (studioId) {
      setStudioId(studioId);
    }
  }, [studioId]);

  const redirectToConnectPage = useCallback(async (erpData) => {
    try {
      const showName = erpData.showName;
      let verifiedData = erpData;

      const { data } = await post('/event/eventForName', { name: showName });
      const eventData = data;
      localStorage.setItem('participantEvent', JSON.stringify(eventData));

      const getUuidResponse = await post('/participant/getUuid', {
        pandoV1UNID: verifiedData.UNID,
        erpId: verifiedData.erpId,
        eventId: eventData._id,
        firstName: verifiedData.firstName,
        lastName: verifiedData.lastName,
        username: verifiedData.username,
        phone: verifiedData.phone,
        location: verifiedData.location,
        role: verifiedData.role,
        metadata: verifiedData.metadata,
      });
      const { uuid, role, token: authToken, eventParticipantId, phone, location } = getUuidResponse.data;
      setToken(authToken);

      verifiedData.attendName = getUuidResponse.data.name;
      verifiedData.role = role || verifiedData.role;
      verifiedData.uuid = uuid;
      verifiedData.event = eventData;
      verifiedData.eventParticipantId = eventParticipantId;
      verifiedData.systemCheck = null;
      verifiedData.phone = phone;
      verifiedData.location = location;
      verifiedData.showName = showName;
      verifiedData.studioId = studioId;

      MediaDeviceUtil.notesData = verifiedData;

      const loginReportingData = {
        type: 'login',
        showName,
        username: verifiedData.username,
        unid: verifiedData.erpId,
        role: verifiedData.role,
      };
      localStorage.setItem('loginReportingData', JSON.stringify(loginReportingData));
      sessionStorage.setItem('venue-login', 'true');

      store.dispatch(push({ pathname: '/connect', state: verifiedData, search }));
    } catch (error) {
      console.error('venue login', error);
    }
  }, []);

  return (
    <div className='venue-login'>
      <LoginForm eventId={eventId} onSuccessfulLogin={redirectToConnectPage} />
    </div>
  );
}

export default VenueLogin;
