import { store } from '../store';

export function isFireFox() {
  return typeof InstallTrigger !== 'undefined';
}

export function isSafari() {
  const isAppleVendor = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;
  const isSafari = navigator.userAgent.indexOf('Safari') > -1; // Chrome returns Safari too but its not Apple vendor
  return isAppleVendor && isSafari;
}

export const disableSwitchSpeaker = !Boolean(document.createElement('audio').setSinkId);

export const isIpad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';

export function reloadBrowser(autoConnect = false) {
  let params = '';
  const { origin } = window.location;
  const {
    app: { loginUrlSearchParams },
  } = store.getState();
  const search = loginUrlSearchParams;
  if (autoConnect === true) {
    if (search && search.length > 0) {
      params = `${search}&auto-connect=true`;
    } else {
      params = '?auto-connect=true';
    }
  } else {
    params = loginUrlSearchParams;
  }
  window.location.href = `${origin}/login${params}`;
}

export function getMessageBoxTransform(height) {
  let scale = 1;
  if (height < 768 && height >= 630) {
    scale = 0.8;
  } else if (height < 630 && height >= 600) {
    scale = 0.7;
  } else if (height < 600 && height >= 550) {
    scale = 0.6;
  } else if (height < 550) {
    scale = 0.5;
  }
  return { transform: `scale(${scale})` };
}

export async function loadScript(url, cb) {
  return new Promise((resolve) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      cb && cb();
      resolve();
      return;
    }

    const script = document.createElement('script');
    const body = document.getElementsByTagName('body')[0];
    script.src = url;
    script.onload = () => {
      cb && cb();
      resolve();
    };
    body.appendChild(script);
  });
}

export async function loadStyle(url, cb) {
  return new Promise((resolve) => {
    if (document.querySelector(`link[href="${url}"]`)) {
      cb && cb();
      resolve();
      return;
    }

    const link = document.createElement('link');
    const head = document.getElementsByTagName('head')[0];
    link.href = url;
    link.rel = 'stylesheet';
    link.onload = () => {
      cb && cb();
      resolve();
    };
    head.appendChild(link);
  });
}

export const isMobile = () => sessionStorage.getItem('isMobile') === 'true';

// useReduxStore is false only for archive player
export const redirect = (url, appendAttendeeInformation, useReduxStore = true, erpAccountId = null) => {
  if (appendAttendeeInformation) {
    if (!erpAccountId) {
      let meetingId;
      let username;
      let unid;

      if (useReduxStore) {
        const {
          router: {
            location: { state },
          },
        } = store.getState();
        meetingId = state.meetingId;
        username = state.username;
        unid = state.UNID;
      } else {
        meetingId = window.history.state['roomId'];
        username = window.history.state['email'];
        unid = window.history.state['unid'];
      }

      let splitted_meeting_id = meetingId.split('-');
      let PID = '';
      const len = splitted_meeting_id.length;
      if (len > 1) {
        PID = splitted_meeting_id[len - 2] + '-' + splitted_meeting_id[len - 1];
      }
      if (url.indexOf('?') === -1) {
        url += `?PID=${PID}&User=${username}&UNID=${unid}`;
      } else {
        url += `&PID=${PID}&User=${username}&UNID=${unid}`;
      }
    } else {
      const {
        router: {
          location: { state },
        },
      } = store.getState();
      if (url.indexOf('?') === -1) {
        url += `?registrantId=${state.erpId}`;
      } else {
        url += `&registrantId=${state.erpId}`;
      }
      if (url.includes('platformEventId') === false) {
        url += `&platformEventId=${state.event._id}`;
      }
    }
  }
  window.location.replace(`${url}`);
};
