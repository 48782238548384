import React, { useEffect } from 'react';
import Select from '../../common/Select';
import { disableSwitchSpeaker, isSafari } from '../../utils/browser-util';
const isMacOS = window.navigator.platform.toUpperCase().indexOf('MAC') >= 0;

function MediaDevicesPopover({
  videoInputDevices,
  supportedResolutions,
  audioInputDevices,
  audioOutputDevices,
  videoInput,
  resolution,
  audioInput,
  audioOutput,
  onVideoInputChange,
  onResolutionChange,
  onAudioInputChange,
  onAudioOutputChange,
}) {
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <div className='media-devices' style={{ marginTop: 0 }}>
      <div className='device-list-container'>
        <div className='label'>Video</div>
        <Select
          list={videoInputDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={onVideoInputChange}
          selected={videoInput && videoInput.deviceId}
          currentOption={videoInput && videoInput.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Resolution</div>
        <Select
          list={supportedResolutions}
          listKey='value'
          listLabel='label'
          onChange={onResolutionChange}
          selected={resolution && resolution.value}
          currentOption={resolution && resolution.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Mic.</div>
        <Select
          list={audioInputDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={onAudioInputChange}
          selected={audioInput && audioInput.deviceId}
          currentOption={audioInput && audioInput.label}
          containerStyle={{ width: '100%' }}
          small
        />
      </div>
      <div className='device-list-container'>
        <div className='label'>Speaker</div>
        <Select
          list={audioOutputDevices}
          listKey='deviceId'
          listLabel='label'
          onChange={onAudioOutputChange}
          selected={audioOutput && audioOutput.deviceId}
          currentOption={audioOutput && audioOutput.label}
          containerStyle={{ width: '100%' }}
          small
          disabled={isSafari() || disableSwitchSpeaker}
        />
      </div>
    </div>
  );
}

export default MediaDevicesPopover;
