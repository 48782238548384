import { actions } from './actions';

const initialState = {
  offWallReactionStatus: null,
  loginUrlSearchParams: null,
  isOffWallAttendeeListOpen: false,
  isVenueParticipant: false,
  outputSpeakerId: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_OFF_WALL_REACTION_STATUS:
      return {
        ...state,
        offWallReactionStatus: action.payload.data,
      };
    case actions.SET_LOGIN_URL_SEARCH_PARAMS:
      return {
        ...state,
        loginUrlSearchParams: action.payload.url,
      };
    case actions.SET_IS_OFF_WALL_ATTENDEE_LIST_OPEN:
      return {
        ...state,
        isOffWallAttendeeListOpen: action.payload.value,
      };
    case actions.SET_IS_VENUE_PARTICIPANT:
      return {
        ...state,
        isVenueParticipant: action.payload.value,
      };
    case actions.SET_OUTPUT_SPEAKER_ID:
      return {
        ...state,
        outputSpeakerId: action.payload.value,
      }
    default:
      return state;
  }
}
