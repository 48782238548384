import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import BrowserCheck from './BrowserCheck';
import { put } from '../../services/api';
import { store } from '../../store';
import PandoLogo from '../../assets/pando-logo.png';
import './Onboarding.css';
import { isMobile } from '../../utils/browser-util';
import MediaDeviceUtil from '../../utils/media-device-util';

export default function BrowserCheckPage(props) {
  const [loginLink, setLoginLink] = useState(null);
  const [hidePage, setHidePage] = useState(false);

  useEffect(() => {
    const { origin, search } = window.location;
    const loginUrl = `${origin}/login${search}`;
    setLoginLink(loginUrl);
    if (!MediaDeviceUtil.notesData) {
      window.location.href = loginUrl;
    }
  }, []);

  function onComplete(supported) {
    if (supported !== false) {
      const { state, search } = props.location;
      store.dispatch(push({ pathname: '/connect', state, search }));
    } else if (isMobile()) {
      setHidePage(true);
    }
  }

  async function onUpdateProgress(data) {
    try {
      const { eventParticipantId } = props.location.state;
      await put(`/eventParticipant/${eventParticipantId}`, { systemCheck: data });
    } catch (error) {
      console.error(error);
    }
  }

  if (!loginLink) {
    return null;
  }

  return (
    <div className='onboarding-container' style={{ display: hidePage ? 'none' : 'flex' }}>
      <div className='onboarding'>
        <div className='onboarding-title'>
          <img src={PandoLogo} alt='Pando Logo' width={135} />
        </div>
        <div className='onboarding-content'>
          <BrowserCheck title='Checking your Browser' skipAnimation={true} loginLink={loginLink} onComplete={onComplete} onUpdateProgress={onUpdateProgress} />
        </div>
      </div>
    </div>
  );
}
