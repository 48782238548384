import React, { useState, useEffect } from 'react';
import Select from '../../common/Select';
import MediaDeviceUtil from '../../utils/media-device-util';
import '../../common/MediaDevices/MediaDevices.css';
import Storage from '../../utils/storage';
import { disableSwitchSpeaker, isSafari } from '../../utils/browser-util';
import Logger from '../../utils/logger';
import Popover from 'react-popover';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../janusBreakoutRoom/IconButton';

const localStorage = Storage.getLocalStorage();

const logger = new Logger('Devices');

function Devices({ onDeviceChange }) {
  const [_videoDevices, setVideoDevices] = useState(null);
  const [_audioInputDevices, setAudioInputDevices] = useState(null);
  const [_audioOutputDevices, setAudioOutputDevices] = useState(null);
  const [_selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [_selectedAudioInputDevice, setSelectedAudioInputDevice] = useState('');
  const [_selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState('');
  const [showDevices, setShowDevices] = useState(false);

  async function updateMediaDevices() {
    const devices = await MediaDeviceUtil.getAvailableDevices(false);
    const videoDevices = devices.filter((v) => v.kind === 'videoinput');
    const audioInputDevices = devices.filter((v) => v.kind === 'audioinput');
    const audioOutputDevices = devices.filter((v) => v.kind === 'audiooutput');

    logger.info('Detected device changes', { videoDevices, audioInputDevices, audioOutputDevices });

    setVideoDevices(videoDevices);
    setAudioInputDevices(audioInputDevices);
    setAudioOutputDevices(audioOutputDevices);

    const videoDevice = MediaDeviceUtil.getSelectedDevice(videoDevices, _selectedVideoDevice);
    if (!_selectedVideoDevice || _selectedVideoDevice.deviceId !== videoDevice.deviceId) {
      logger.info('##### updating ui for video');
      setSelectedVideoDevice(videoDevice);
      onDeviceChange(videoDevice.deviceId, 'video');
    }

    const audioDevice = MediaDeviceUtil.getSelectedDevice(audioInputDevices, _selectedAudioInputDevice);
    if (!_selectedAudioInputDevice || _selectedAudioInputDevice.deviceId !== audioDevice.deviceId) {
      logger.info('##### updating ui for audio');
      setSelectedAudioInputDevice(audioDevice);
      onDeviceChange(audioDevice.deviceId, 'audio');
    }

    const speakerDevice = MediaDeviceUtil.getSelectedDevice(audioOutputDevices, _selectedAudioOutputDevice);
    if (_selectedAudioOutputDevice || _selectedAudioOutputDevice.deviceId !== speakerDevice.deviceId) {
      logger.info('##### updating ui for speaker');
      setSelectedAudioOutputDevice(speakerDevice);
      onDeviceChange(speakerDevice.deviceId, 'speaker');
    }
  }

  useEffect(() => {
    window.navigator.mediaDevices.addEventListener('devicechange', updateMediaDevices);
    const interval = setInterval(() => navigator.mediaDevices.enumerateDevices(), 3000);
    return () => {
      clearInterval(interval);
      window.navigator.mediaDevices.removeEventListener('devicechange', updateMediaDevices);
    }
  }, [_selectedVideoDevice, _selectedAudioInputDevice, _selectedAudioOutputDevice]);

  useEffect(() => {
    (async () => {
      const availableDevices = await MediaDeviceUtil.getAvailableDevices(false);

      const filteredVideoDevices = availableDevices.filter((d) => d.kind === 'videoinput');
      const filteredAudioInputDevices = availableDevices.filter((d) => d.kind === 'audioinput');
      const filteredAudioOutputDevices = availableDevices.filter((d) => d.kind === 'audiooutput');

      setVideoDevices(filteredVideoDevices);
      setAudioInputDevices(filteredAudioInputDevices);
      setAudioOutputDevices(filteredAudioOutputDevices);

      const savedVideoDevice = localStorage.getItem('videoInput');
      const savedAudioInputDevice = localStorage.getItem('audioInput');
      const savedAudioOutDevice = localStorage.getItem('audioOutput');

      if (savedVideoDevice) {
        const chosenVideoDevice = MediaDeviceUtil.getSelectedDevice(filteredVideoDevices, JSON.parse(savedVideoDevice));
        logger.info('chosen video device', { chosenVideoDevice });
        setSelectedVideoDevice(chosenVideoDevice);
      }

      if (savedAudioInputDevice) {
        const chosenAudioInputDevice = MediaDeviceUtil.getSelectedDevice(filteredAudioInputDevices, JSON.parse(savedAudioInputDevice));
        logger.info('chosen audio input device', { chosenAudioInputDevice });
        setSelectedAudioInputDevice(chosenAudioInputDevice);
      }

      if (savedAudioOutDevice) {
        const chosenAudioOutputDevice = MediaDeviceUtil.getSelectedDevice(filteredAudioOutputDevices, JSON.parse(savedAudioOutDevice));
        logger.info('chosen audio output device', { chosenAudioOutputDevice });
        setSelectedAudioOutputDevice(chosenAudioOutputDevice);
      }
    })();
  }, []);

  function _onDeviceChange(option, type) {
    if (type === 'video') {
      localStorage.setItem(`videoInput`, JSON.stringify(option));
      setSelectedVideoDevice(option);
      onDeviceChange(option.deviceId, type);
    } else if (type === 'audio') {
      localStorage.setItem(`audioInput`, JSON.stringify(option));
      setSelectedAudioInputDevice(option);
      onDeviceChange(option.deviceId, type);
    } else if (type === 'speaker') {
      localStorage.setItem(`audioOutput`, JSON.stringify(option));
      setSelectedAudioOutputDevice(option);
      onDeviceChange(option.deviceId, type);
    }
  }

  return (
    <Popover
      isOpen={showDevices}
      body={<div className='settings'>
        <div className='device-list-container'>
          <div className='label'>Camera</div>
          <Select
            list={_videoDevices}
            listKey='deviceId'
            listLabel='label'
            onChange={(option) => _onDeviceChange(option, 'video')}
            selected={_selectedVideoDevice && _selectedVideoDevice.value}
            currentOption={_selectedVideoDevice && _selectedVideoDevice.label}
            containerStyle={{ width: '100%' }}
            small
          />
        </div>
        <div className='device-list-container'>
          <div className='label'>Microphone</div>
          <Select
            list={_audioInputDevices}
            listKey='deviceId'
            listLabel='label'
            onChange={(option) => _onDeviceChange(option, 'audio')}
            selected={_selectedAudioInputDevice && _selectedAudioInputDevice.value}
            currentOption={_selectedAudioInputDevice && _selectedAudioInputDevice.label}
            containerStyle={{ width: '100%' }}
            small
          />
        </div>
        <div className='device-list-container'>
          <div className='label'>Speaker</div>
          <Select
            list={_audioOutputDevices}
            listKey='deviceId'
            listLabel='label'
            onChange={(option) => _onDeviceChange(option, 'speaker')}
            selected={_selectedAudioOutputDevice && _selectedAudioOutputDevice.value}
            currentOption={_selectedAudioOutputDevice && _selectedAudioOutputDevice.label}
            containerStyle={{ width: '100%' }}
            small
            disabled={isSafari() || disableSwitchSpeaker}
          />
        </div>
      </div>}
      onOuterAction={() => setShowDevices(false)}
      tipSize={6}
      place='right'
      style={{ fill: '#1e272f', zIndex: 99999 }}
    >
      <IconButton onIcon={faCog} offIcon={faCog} value={true} onChange={() => setShowDevices(!showDevices)} tooltip='Device Settings' />
    </Popover>
  );
}

export default Devices;
