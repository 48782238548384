import io from 'socket.io-client';
import swal from 'sweetalert';
import Storage from '../utils/storage';
import Deferred from '../utils/deferred';

const localStorage = Storage.getLocalStorage();

class SocketClient {
  static joinedRooms = [];
  static listeners = {};
  static clientActionStates = {};

  static async setup(url, uuid, eventId, studioId, token, isOffWallParticipant = false, isReconnect = false) {
    SocketClient._connected = new Deferred();
    const flipVideoVertically = window.orientation === 90; // rotated iPad
    const disconnectReason = SocketClient._isWebRTCDisconnect ? 'WebRTC' : 'Socket.io';

    SocketClient._connecting = true;
    let socketServerUrl = url;
    if (window.location.host.includes('ticmeetings.com') && url.includes('letspando.com')) {
      socketServerUrl = url.replace('letspando.com', 'ticmeetings.com');
    }
    SocketClient.socket = io.connect(socketServerUrl, {
      auth: {
        type: 'CLIENT',
        metadata: {
          uuid: uuid || localStorage.getItem('uuid'),
          eventId,
          studioId,
          flipVideoVertically,
          token,
          loginReportingData: JSON.parse(localStorage.getItem('loginReportingData')),
          isOffWallParticipant,
        },
        isReconnect,
        disconnectReason,
      },
      reconnection: false,
      transports: ['websocket', 'polling'],
    });
    SocketClient._connecting = false;

    SocketClient.socket.once('connect_error', (error) => {
      SocketClient.socket.io.opts.transports = ['polling', 'websocket'];
      console.error('socket on connect_error', error);
      SocketClient.socket.removeAllListeners();
      setTimeout(async () => {
        if (!SocketClient._connecting) {
          await SocketClient.setup(url, uuid, eventId, studioId, token, isOffWallParticipant);
        }
      }, 5000);
    });

    SocketClient.socket.once('connect', async () => {
      console.log(new Date(), 'SocketClient connect', isReconnect, SocketClient.socket.id);
      SocketClient._connected.resolve();

      clearInterval(SocketClient.timerId);
      SocketClient._isWebRTCDisconnect = null;

      // Sometimes connect gets triggered with no socket.id
      if (SocketClient.socket.id && isReconnect) {
        if (SocketClient.reconnectWebRTC) {
          SocketClient.webRTCConnectionComplete = new Deferred();
          SocketClient.reconnectWebRTC.resolve(); // Trigger an WebRTC reconnect
        }

        SocketClient.webRTCConnectionComplete &&
          SocketClient.webRTCConnectionComplete.promise.then(() => {
            // We have successfully established the two RTC connections
            Object.entries(SocketClient.clientActionStates).forEach(([action, data]) => {
              SocketClient.emitClientAction(action, data);
            });
          });
      } else if (!SocketClient.socket.id) {
        SocketClient.socket.removeAllListeners();
      }

      SocketClient.webRTCDisconnectedDeferred = new Deferred();
      SocketClient.webRTCDisconnectedDeferred.promise.then(() => {
        // Either we were unable to establish an RTC connection or a connection state change occurred
        // The participant in both cases has to hit the 'Reconnect' button
        if (SocketClient.socket.connected) {
          SocketClient.socket.disconnect();
          SocketClient._isWebRTCDisconnect = true;
        }
      });
    });

    SocketClient.socket.once('disconnect', () => {
      console.log(new Date(), 'SocketClient disconnect', isReconnect);
      SocketClient.socket.removeAllListeners();

      clearInterval(SocketClient.timerId);
      SocketClient.timerId = setInterval(async () => {
        if (!SocketClient.noConnectionAlertShowing && !SocketClient.isBackgroundIpad && !SocketClient._connecting) {
          await SocketClient.setup(url, uuid, eventId, studioId, token, isOffWallParticipant, true);
        }
      }, 5000);
    });

    SocketClient.socket.on('duplicate-connection', () => {
      swal({
        title: 'Error',
        text: 'Looks like you may be trying to connect more than once from the same device. Please close other browsers and try logging in again.',
      });
      SocketClient.socket.removeAllListeners();
    });

    SocketClient.joinedRooms.forEach((roomName) => SocketClient.socket.emit('join-room', roomName));
    for (const [type, func] of Object.entries(SocketClient.listeners)) {
      SocketClient.socket.on(type, func);
    }

    window.onorientationchange = () => {
      let flipVideoVertically = false;
      if (window.orientation === 90) {
        flipVideoVertically = true;
      }

      SocketClient.socket.emit('ipad-orientation-changed', { uuid: uuid || localStorage.getItem('uuid'), flipVideoVertically });
    };
    return SocketClient._connected.promise;
  }

  static emit(type, data) {
    if (SocketClient.socket) SocketClient.socket.emit(type, data);
  }

  static emitClientAction(action, data) {
    if (!SocketClient.socket) return;
    const uuid = localStorage.getItem('uuid');
    SocketClient.socket.emit('client-action', {
      uuid,
      action,
      ...data,
    });
    if (action === 'pause') delete SocketClient.clientActionStates['unpause'];
    if (action === 'unpause') delete SocketClient.clientActionStates['pause'];
    SocketClient.clientActionStates[action] = data;
  }

  static joinRoom(roomName) {
    SocketClient.socket.emit('join-room', roomName);
    if (!roomName.endsWith('-client-send') && !roomName.endsWith('-client-recv')) {
      SocketClient.joinedRooms.push(roomName);
    }
  }

  static leaveRoom(roomName) {
    SocketClient.socket.emit('leave-room', roomName);
    const roomIndex = SocketClient.joinedRooms.indexOf(roomName);
    if (roomIndex !== -1) {
      SocketClient.joinedRooms.splice(roomIndex, 1);
    }
  }

  static addListener(type, func) {
    SocketClient.socket.on(type, func);
    SocketClient.listeners[type] = func;
  }

  static removeListener(type) {
    SocketClient.socket.removeListener(type);
    delete SocketClient.listeners[type];
  }

  static removeAllListeners() {
    Object.keys(SocketClient.socket._callbacks).forEach((key) => {
      if (key !== '$disconnect') {
        SocketClient.socket.removeListener(key.replace('$', ''));
      }
    });
  }

  static disconnect() {
    if (SocketClient.timerId) clearInterval(SocketClient.timerId);
    if (SocketClient._heartbeat) clearInterval(SocketClient._heartbeat);

    SocketClient.socket.removeAllListeners();
    SocketClient.socket.disconnect();
  }
}

export default SocketClient;
