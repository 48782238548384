import React, { PureComponent } from "react";
import {
  browserName,
  browserVersion,
  isMobile,
  isIPad13,
} from "react-device-detect";
import swal from "@sweetalert/with-react";
import Button from "../../common/Button";
import PandoLogo from "../../assets/pando-logo.png";
import QuestionIcon from "../../assets/icon-question-mark-circle.svg";
import RedQuestionIcon from "../../assets/icon-red-question-mark-circle.svg";
import GreenCheckIcon from "../../assets/icon-check-circle.svg";
import ErrorCircleIcon from "../../assets/icon-error-circle.svg";
import ChromeIcon from "../../assets/browser-chrome.svg";
import EdgeIcon from "../../assets/browser-edge.svg";
import FirefoxIcon from "../../assets/browser-firefox.svg";
import IeIcon from "../../assets/browser-ie.svg";
import OperaIcon from "../../assets/browser-opera.svg";
import SafariIcon from "../../assets/browser-safari.svg";
import BrowserIcon from "../../common/Icons/BrowserIcon";
import { loadScript, isMobile as _isMobile } from "../../utils/browser-util";

const { REACT_APP_OFF_WALL_SYSTEM_CHECK_VIDEO_URL } = process.env;

const PlayButton = ({ disabled, onClick }) => {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        display: "inline-block",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        opacity="0.85"
      >
        <circle cx="20" cy="20" r="20" fill="white" />
        <polygon points="17,12 28,20 17,28" fill="black" />
      </svg>
    </div>
  );
};

const PauseButton = ({ disabled, onClick }) => {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        display: "inline-block",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        opacity="0.85"
      >
        <circle cx="20" cy="20" r="20" fill="white" />
        <rect x="14" y="12" width="5" height="16" fill="black" />
        <rect x="22" y="12" width="5" height="16" fill="black" />
      </svg>
    </div>
  );
};

export default class OffWallSystemCheck extends PureComponent {
  state = {
    browserSupported: true,
    mediaSupported: false,
    mediaIconState: QuestionIcon,
    browserString: "",
    browserIcon: null,
    yesButtonDisabled: true,
    isVideoPlaying: false,
    shouldShowControls: true,
  };

  async componentDidMount() {
    if (_isMobile()) {
      this._applyMobileStyles();
      window.addEventListener("orientationchange", () => {
        setTimeout(this._applyMobileStyles, 500);
      });
    }

    await loadScript(
      "https://player.live-video.net/1.20.0/amazon-ivs-player.min.js",
      () => {
        if (window.IVSPlayer.isPlayerSupported) {
          this.videoElement = document.getElementById("off-wall-player");
          this.player = window.IVSPlayer.create();
          this.player.attachHTMLVideoElement(this.videoElement);

          const { PlayerState } = window.IVSPlayer;
          const { PLAYING } = PlayerState;

          this.videoElement.addEventListener("play", () => {
            this.setState({
              yesButtonDisabled: false,
              isVideoPlaying: true,
              shouldShowControls: false,
            });
          });

          this.videoElement.addEventListener("playing", () => {
            this.setState({
              yesButtonDisabled: false,
              isVideoPlaying: true,
              shouldShowControls: false,
            });
          });

          this.videoElement.addEventListener("pause", () => {
            this.setState({ isVideoPlaying: false });
          });

          this.player.addEventListener(PLAYING, () => {
            this.setState({ yesButtonDisabled: false });
          });

          this.player.load(REACT_APP_OFF_WALL_SYSTEM_CHECK_VIDEO_URL);
        } else {
          console.error(
            "The current browser does not support the Amazon IVS player."
          );
        }
      }
    );

    this._validateDevice(this.props.location.state);
  }

  _applyMobileStyles() {
    const orientation = sessionStorage.getItem("orientation");
    const body = document.getElementsByTagName("body")[0];
    const rootDiv = document.getElementById("root");

    body.classList.add("scrollbars");
    rootDiv.classList.add("scrollbars");
    if (orientation === "portrait") {
      rootDiv.style.height = "100%";
      rootDiv.style.width = "auto";
    } else {
      rootDiv.style.height = "auto";
      rootDiv.style.width = "100%";
    }
  }

  async _validateDevice() {
    let returnString = "";
    let isSupported = false;

    if (!isMobile || isIPad13) {
      switch (browserName) {
        case "Chrome":
          returnString = browserName + " " + browserVersion;
          this.setState({ browserIcon: ChromeIcon });
          isSupported = true;
          break;
        case "Safari":
          this.setState({ browserIcon: SafariIcon });
          if (browserVersion >= 13) {
            returnString = browserName + " " + browserVersion;
            isSupported = true;
          } else {
            returnString = browserName + " " + browserVersion;
            isSupported = false;
          }
          break;
        case "Mobile Safari":
          this.setState({ browserIcon: SafariIcon });
          if (browserVersion >= 13) {
            returnString = browserName + " " + browserVersion;
            isSupported = true;
          } else {
            returnString = browserName + " " + browserVersion;
            isSupported = false;
          }
          break;
        case "Edge":
          this.setState({ browserIcon: EdgeIcon });
          if (browserVersion >= 80) {
            returnString = browserName + " " + browserVersion;
            isSupported = true;
          } else {
            returnString = browserName + " " + browserVersion;
            isSupported = false;
          }
          break;
        case "Firefox":
          returnString = browserName + " " + browserVersion;
          this.setState({ browserIcon: FirefoxIcon });
          isSupported = false;
          break;
        case "IE":
          returnString = browserName + " " + browserVersion;
          this.setState({ browserIcon: IeIcon });
          isSupported = false;
          break;
        case "Opera":
          returnString = browserName + " " + browserVersion;
          this.setState({ browserIcon: OperaIcon });
          isSupported = false;
          break;
        default:
          returnString = browserName + " " + browserVersion;
          isSupported = false;
          break;
      }
    } else {
      isSupported = false;
    }

    if (isSupported == true) {
      this.setState({ browserSupported: true, browserString: returnString });
    } else {
      this.setState({ browserSupported: false, browserString: returnString });
    }
  }

  confirmYes = () => {
    this.setState({ mediaSupported: true, mediaIconState: GreenCheckIcon });
    const { browserSupported } = this.state;
    if (browserSupported == true || isMobile) {
      swal({
        title: "System Check Confirmation",
        text: "You have passed the system check",
      });
    } else {
      swal({
        title: "Warning",
        text: "Browser not supported",
      });
    }
  };

  confirmNo = () => {
    this.setState({ mediaSupported: false, mediaIconState: ErrorCircleIcon });
    swal({
      title: "Warning",
      text: "One or more system check components have failed. Please refer to your meeting confirmation email or connection instructions for help desk support contact information.",
    });
  };

  render() {
    const {
      browserSupported,
      browserString,
      browserIcon,
      mediaIconState,
      yesButtonDisabled,
      shouldShowControls,
      isVideoPlaying,
    } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#181f26",
          overflow: "auto",
        }}
      >
        <div
          style={{
            padding: "30px 40px",
            backgroundColor: "rgb( 31, 43, 55)",
            color: "#fff",
            marginTop: isMobile ? 85 : 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              verticalAlign: "top",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ verticalAlign: "top" }}>
              Pando System Check – Off-Wall Participant
            </h3>
            <img src={PandoLogo} style={{ width: 135 }} />
          </div>
          <div>
            <table>
              <tbody>
                <tr style={{ backgroundColor: "#24313F" }}>
                  <td
                    style={{ verticalAlign: "top", padding: 10, fontSize: 12 }}
                  >
                    Browser Check
                  </td>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    {browserSupported ? (
                      <img src={GreenCheckIcon} alt={""} width={24} />
                    ) : (
                      <img
                        src={!isMobile ? ErrorCircleIcon : RedQuestionIcon}
                        alt={""}
                        width={24}
                      />
                    )}
                  </td>
                  <td style={{ verticalAlign: "top", padding: 10 }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        {!isMobile ? (
                          <BrowserIcon
                            icon={browserIcon}
                            name={browserString}
                          />
                        ) : null}
                        <div
                          style={{
                            fontSize: 11,
                            backgroundColor: "rgb(72, 29, 37)",
                            margin: !isMobile ? 5 : 0,
                            padding: "7px 15px",
                            borderRadius: 10,
                            maxWidth: 330,
                          }}
                        >
                          If your event is configured to allow participation on
                          a mobile phone, mobile devices will be supported. If
                          not, please be prepared to login on a desktop/laptop
                          computer, or iPad.
                        </div>
                      </div>
                      <div style={{ fontSize: 13, marginTop: 25 }}>
                        Supported browsers for the purposes of a green check
                        are:{" "}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingTop: 10,
                        }}
                      >
                        <BrowserIcon icon={ChromeIcon} name="Google Chrome" />
                        <BrowserIcon icon={SafariIcon} name="Safari" />
                        <BrowserIcon icon={EdgeIcon} name="Microsoft Edge" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#24313F" }}>
                  <td
                    style={{ verticalAlign: "top", padding: 10, fontSize: 12 }}
                  >
                    Media Playback Check
                  </td>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    <img src={mediaIconState} alt={""} width={24} />
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                      }}
                    >
                      <div
                        id="videoPresentationContainer"
                        style={{ width: 200, position: "relative" }}
                        onTouchEnd={() => {
                          this.setState({ shouldShowControls: true });
                          setTimeout(
                            () => this.setState({ shouldShowControls: false }),
                            3000
                          );
                        }}
                        onMouseEnter={() =>
                          this.setState({ shouldShowControls: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ shouldShowControls: false })
                        }
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 10,
                            willChange: "opacity",
                            transition: "opacity 0.5s",
                            opacity:
                              shouldShowControls || !isVideoPlaying ? 1 : 0,
                          }}
                        >
                          {isVideoPlaying ? (
                            <PauseButton
                              disabled={!shouldShowControls}
                              onClick={() => this.videoElement.pause()}
                            />
                          ) : (
                            <PlayButton
                              disabled={!shouldShowControls}
                              onClick={() => this.videoElement.play()}
                            />
                          )}
                        </div>
                        <video
                          id="off-wall-player"
                          muted={false}
                          controls={false}
                          style={{ width: 200 }}
                          playsInline
                          disablePictureInPicture
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          paddingLeft: 40,
                        }}
                      >
                        <label
                          style={{
                            fontSize: 13,
                            marginLeft: 5,
                            marginBottom: 5,
                          }}
                        >
                          Please click the play button on the left.
                          <br /> Can you see and hear the video clip?
                        </label>
                        <div
                          style={{
                            paddingTop: 10,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Button
                            text="Yes"
                            onClick={this.confirmYes}
                            disabled={yesButtonDisabled}
                            containerStyle={{
                              height: 30,
                              width: 110,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                          <Button
                            type={"secondary"}
                            text="No"
                            onClick={this.confirmNo}
                            containerStyle={{
                              height: 30,
                              width: 110,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
