import uuidv4 from 'uuid';

export default class Logger {
  constructor(prefix, trace) {
    this.prefix = prefix;
    this.trace = trace || uuidv4();
  }

  _log(func, ...args) { return func(`${this.trace}:${this.prefix}`, ...args); }

  info(...args) { return this._log(console.info, ...args); }

  error(...args) { return this._log(console.error, ...args); }

  warn(...args) { return this._log(console.warn, ...args); }

  deeper(prefix, newTrace = false) {
    return new Logger(`${this.prefix}:${prefix}`, newTrace ? uuidv4() : this.trace);
  }
}
