import uuidv4 from 'uuid/v4';
import { post, get } from '../../services/api';
import Storage from '../../utils/storage';

const localStorage = Storage.getLocalStorage();

const _generateRandomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

class API {
  static verifyToken = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await post('/accessToken/verify', { token });
        resolve({ valid: true, credentials: response.data });
      } catch (err) {
        if (err.response) {
          console.log(err.response);
        }
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          let searchParams = new URLSearchParams(window.location.search);
          searchParams.delete('accessToken');
          searchParams.delete('auto-connect');
          window.location.replace(`/connect?${searchParams.toString()}`);
        }
        resolve({ valid: false, credentials: null });
      }
    });
  };

  static getIceServers = async () => {
    try {
      const response = await get('/studio/iceServers');
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  static requestSupport = async (uuid, event, supportRequested) => {
    try {
      return await post('/breakoutRoom/requestTechSupport', { uuid, event, supportRequested });
    } catch (error) {
      console.log(error);
    }
  };

  static getTokenForTestClient = async (event) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uuid = localStorage.getItem('uuid');
        const response = await post('/breakoutRoom/tokenForTestClient/', { uuid, event });
        resolve({ valid: true, credentials: response.data });
      } catch (error) {
        console.log(error);
        resolve({ valid: false, credentials: null });
      }
    });
  };

  static verifyTestClient = async (uuid, event) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await post('/breakoutRoom/verifyClient', { uuid, event });
        resolve({ valid: true, credentials: response.data });
      } catch (error) {
        console.log(error);
        resolve({ valid: false, credentials: null });
      }
    });
  };
}

export default API;
