import React, { PureComponent } from 'react';
import swal from '@sweetalert/with-react';
import { debounce } from 'throttle-debounce';
import OffWallStreamUnavailable from '../../common/Messages/OffWallStreamUnavailable';
import EnterMeeting from '../../common/Messages/EnterMeeting';
import Spinner from '../../common/Spinner';
import VideoJS from '../../common/VideoJS';
import { isMobile, isIpad } from '../../utils/browser-util';
import { put } from '../../services/api';

export default class BroadcastStream extends PureComponent {
  state = {
    streamUnavailable: false,
    isPlaying: null,
    customStyle: {},
  };

  _playerRef = React.createRef();

  async componentDidMount() {
    this._debouncedHandleResize = debounce(250, this._handleResize);
    window.addEventListener('resize', this._debouncedHandleResize);
    if (isIpad) {
      window.addEventListener('orientationchange', this._onOrientationChange);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._debouncedHandleResize);
    if (isIpad) {
      window.removeEventListener('orientationchange', this._onOrientationChange);
    }
  }

  _onOrientationChange = () => {
    setTimeout(this._handleResize, 1000);
  };

  _onLoad = async () => {
    const params = new URLSearchParams(window.location.search);
    const isOffWallTestClient = params.get('isOffWallTestClient') === 'true';

    if (!isOffWallTestClient && !isMobile()) {
      await swal({
        buttons: {},
        className: 'swal-custom-content',
        content: (
          <EnterMeeting
            onClick={() => {
              swal.close();
            }}
          />
        ),
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
    this._onEnterMeeting();
  };

  _handleResize = () => {
    const { showHtmlWidget, htmlWidget } = this.props;
    const streamContainer = document.getElementById('stream-container');

    let customStyle = {};
    if (!isMobile() && showHtmlWidget) {
      let widgetWidth = htmlWidget.width;
      if (htmlWidget.widthUnits === '%' && streamContainer) {
        widgetWidth = (streamContainer.offsetWidth * htmlWidget.width) / 100;
      }

      if (htmlWidget.pinTo.indexOf('-left') !== -1) {
        customStyle = {
          paddingLeft: widgetWidth + htmlWidget.spacing.left,
        };
      } else {
        customStyle = {
          paddingRight: widgetWidth + htmlWidget.spacing.right,
        };
      }
    }
    this.setState({ customStyle });
  };

  _onReady = () => {
    this._handleResize();
  };

  _onPlaying = () => {
    this.setState({ isPlaying: true, streamUnavailable: false });
  };

  _onEnded = () => {
    this.setState({ isPlaying: false, streamUnavailable: true });
  };

  _onError = (e) => {
    this.setState({ isPlaying: false, streamUnavailable: true });
  };

  _onEnterMeeting = async () => {
    const { participantData } = this.props;
    this._handleResize();
    this._playerRef.current._stream();
    this.props.onEnterMeeting();
    await put(`/eventParticipant/${participantData._id}`, { hasEnteredMeeting: true });
  };

  _onIpadExitFullScreen = async (orientation) => {
    if (orientation === 'portrait') {
      await swal({
        title: 'Please Refresh',
        text: 'We have detected an issue with fullscreen mode on your device. Please refresh the page to continue.',
        buttons: {
          cancel: 'Refresh',
        },
        dangerMode: true,
      });
      window.location.reload();
    }
  };

  render() {
    const { showEnterMeeting, streamUnavailable, customStyle, isPlaying } = this.state;

    return (
      <div className='off-wall-stream'>
        <div className='off-wall-stream-container' style={customStyle}>
          <div
            className='video-container'
            style={{
              position: 'relative',
              width: '100%',
              height: 'auto',
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              aspectRatio: '16 / 9',
              opacity: showEnterMeeting === true || !isPlaying ? 0 : 1,
            }}
          >
            <VideoJS
              ref={this._playerRef}
              url={this.props.url}
              onLoad={this._onLoad}
              onReady={this._onReady}
              onPlaying={this._onPlaying}
              onEnded={this._onEnded}
              onError={this._onError}
              onIpadExitFullScreen={this._onIpadExitFullScreen}
              loop={true}
            />
          </div>
          {!streamUnavailable && !isPlaying && (
            <div style={{ position: 'absolute' }}>
              <Spinner />
            </div>
          )}
          {streamUnavailable && (
            <div className='message' style={customStyle}>
              <OffWallStreamUnavailable />
            </div>
          )}
        </div>
      </div>
    );
  }
}
